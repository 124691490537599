
// const firebaseConfig = {
//  apiKey: 'AIzaSyB0Tmvjwr7iX3b8hJAiG9QxsNd-U-ikzbc',
//  authDomain: 'petslife-327718.firebaseapp.com',
//  projectId: 'petslife-327718',
//  storageBucket: 'petslife-327718.appspot.com',
//  messagingSenderId: '177710185280',
//  appId: '1:177710185280:web:c41207e96a86ed62ce58a5',
//  measurementId: 'G-L99ZL32VQL',
// };

import firebase from 'firebase';
// import '@firebase/messaging';
// import { initializeApp } from 'firebase/app';
// import firebase from 'firebase/compat';
// import { getMessaging } from 'firebase/messaging';
const firebaseConfig = {
    apiKey: 'AIzaSyDSMvL4K6mUCZoSa11mNKRKpd7tmTaGaEs',
    authDomain: 'pets--oauth-23942.firebaseapp.com',
    projectId: 'pets--oauth-23942',
    storageBucket: 'pets--oauth-23942.appspot.com',
    messagingSenderId: '671030242081',
    appId: '1:671030242081:web:2db13682e3f53c7066ac60',
    measurementId: 'G-LT3VHZ6VCZ'
};

firebase.initializeApp(firebaseConfig);
// alert(firebase.messaging.isSupported());

const auth = firebase.auth();

export {auth, firebase};
// const app = initializeApp(firebaseConfig);
// // const auth = app.auth();
// // const messaging = getMessaging(app);
//
// console.log(messaging);
// export { firebase};
