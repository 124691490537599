import './ErrorPageComponent.scss';

import type { ErrorPageProps } from '../types';
import { ReactComponent as LowConnectionImage } from '../../../../assets/images/error/lov_connection.svg';
import { ReactComponent as WifiIcon } from '../../../../assets/images/low_connection.svg';
import { useErrorPageHandlers } from '../hooks/useHandlers';
import { useTranslation } from 'react-i18next';

export function ErrorPageComponent(props: ErrorPageProps) {
  const { t } = useTranslation();
  const { errorImage, errorType, errorText, onReset } = props;

  const { handleRetry, handleButtonClick, logOut } = useErrorPageHandlers({ errorType, onReset });

  return (
    <section className='errorPage'>
      <div className='errorPage_wrapper'>
        {errorType === 504 ? (
          <>
            <LowConnectionImage className='img-pet' />
            <WifiIcon className='img-wifi' />
          </>
        ) : (
          <img src={errorImage} className={!!errorType ? 'error-img' : 'error-img default'} alt='error icon' />
        )}
        {errorType === 504 ? (
          <div>
            <h1 className='low-connection-title'>{t('NoInternet.lowConnectionTitle')}</h1>
            <p className='low-connection-text'>{t('NoInternet.lowConnectionText')}</p>
          </div>
        ) : !!errorType ? (
          <>
            <p className='errorPage_title'>Error</p>
            <p className='errorPage_text'>{errorText}</p>
          </>
        ) : (
          <p className='errorPage_text default'>{errorText}</p>
        )}
        <div>
          {errorType > 499 ? (
            <button className='no-internet-retry' onClick={handleRetry}>
              {errorType === 504 ? t('ErrorPage.buttons.retry') : t('ErrorPage.buttons.tryAgain')}
            </button>
          ) : (
            <>
              {!!errorType ? (
                <button className='errorPage_button' onClick={handleButtonClick}>
                  {t('ErrorPage.buttons.back')}
                </button>
              ) : (
                <>
                  <button className='errorPage_button' onClick={logOut}>
                    {t('ErrorPage.buttons.tryAgain')}
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}
