import { useCallback, useEffect, useState } from 'react';

import { RequestState } from '../../../../../utils/enums';
import type { TCity } from './types';
import { get } from '../../../../../utils/services';
import { tokenState } from '../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../../utils/hooks';
import { useRecoilState } from 'recoil';

export function useFetchCityByid(city_id?: number) {
  const [token] = useRecoilState(tokenState);
  const isMounted = useIsMounted();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [city, setCity] = useState<TCity | null>(null);
  const [requestState, setRequestState] = useState(RequestState.Idle);

  const fetchCities = useCallback(
    async (city_id: number) => {
      if (!token) return;
      setRequestState(RequestState.Pending);
      const { data, error } = await get('CITY_BY_ID', { city_id: city_id }, '', token);

      if (!isMounted()) return;

      switch (true) {
        case !!data:
          setCity(data);
          setRequestState(RequestState.Success);
          break;
        case !!error:
          fetchingErrorHandler(error);
          setRequestState(RequestState.Error);
          break;
        default:
          setRequestState(RequestState.Idle);
          break;
      }
    },
    [fetchingErrorHandler, isMounted, token],
  );

  useEffect(() => {
    city_id && fetchCities(city_id);
  }, [city_id, fetchCities]);

  return { city, requestState, fetchCities };
}
