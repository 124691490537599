import { ReactComponent as GoogleIcon } from '../../../../../../../assets/images/google_logo.svg';
import GoogleLogin from 'react-google-login';
import type { ReactElement } from 'react';
import { useHandlers } from './useHandlers';

const clientId = '842584830154-9tnlomq92bq8sp10lhlrmp08kli5sgcl.apps.googleusercontent.com';

export function CustomGoogleLogin(): ReactElement {
  const { onSuccess, onFailure } = useHandlers();

  return (
    <GoogleLogin
      clientId={clientId}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy='single_host_origin'
      render={renderProps => <GoogleIcon onClick={renderProps.onClick} />}
    />
  );
}
