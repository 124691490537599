import React, { type ReactElement, useEffect, useState } from 'react';
import errorImage401 from '../../../../assets/images/error/401.svg';
import errorImage403 from '../../../../assets/images/error/403.svg';
import errorImage404 from '../../../../assets/images/error/404.svg';
import errorImage500 from '../../../../assets/images/error/500.svg';
import errorImageDefault from '../../../../assets/images/error/error.svg';
import { ErrorPageComponent } from '../../errors/components';
import { useTranslation } from 'react-i18next';

export function FallbackErrorBoundary({
  error,
  resetErrorBoundary,
}: {
  error: { response?: { status: number } };
  resetErrorBoundary: () => void;
}): ReactElement {
  const { t } = useTranslation();

  const [codeStatus, setCodeStatus] = useState(error?.response ? error.response?.status : 0);
  const [errorImage, setErrorImage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect((): void => {
    switch (codeStatus) {
      case 401:
        setErrorMessage(t('ErrorPage.message.err401'));
        return setErrorImage(errorImage401);
      case 403:
        setErrorMessage(t('ErrorPage.message.err403'));
        return setErrorImage(errorImage403);
      case 404:
        setErrorMessage(t('ErrorPage.message.err404'));
        return setErrorImage(errorImage404);
      case 500:
      case 501:
      case 502:
      case 503:
        setErrorMessage(t('ErrorPage.message.err503'));
        return setErrorImage(errorImage500);
      case 504:
        return;
      default:
        setCodeStatus(0);
        setErrorMessage(t('ErrorPage.message.default'));
        return setErrorImage(errorImageDefault);
    }
  }, [codeStatus, error, t]);

  return (
    <ErrorPageComponent
      errorType={codeStatus}
      errorImage={errorImage}
      errorText={errorMessage}
      onReset={resetErrorBoundary}
    />
  );
}
