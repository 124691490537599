import { UrlsType, get } from '../../../../../../utils/services';
import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from 'react';

import { RequestState } from '../../../../../../utils/enums';
import type { TMessageResponse } from '../types';
import { tokenState } from '../../../../../../utils/atoms';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';

const requestKey = 'MESSAGES' as UrlsType;
const requestData = {
  limit: 20,
  offset: 0,
};

export function useFetchedNotificationData(
  needToCheckData: boolean,
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>,
): [RequestState, TMessageResponse, () => void] {
  const isMounted = useIsMounted();
  const [token] = useRecoilState(tokenState);
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedNotificationData, setFetchedNotificationData] = useState<TMessageResponse>({
    count: 0,
    next: null,
    previous: null,
    results: {
      messages_unseen: [],
      messages_seen: [],
    },
  });
  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const fetchNotificationData = useCallback(
    async requestData => {
      setRequestStatus(RequestState.Pending);

      const response = await get(requestKey, requestData, token, '');
      if (!isMounted()) return;

      if (!!response.error) {
        switch (response.error.response.status) {
          case 404:
            setRequestStatus(RequestState.Success);
            break;
          default:
            fetchingErrorHandler(response.error);
        }
        return;
      }
      setFetchedNotificationData(previousData => {
        if (requestData.offset === 0) return response.data;
        response.data.results.messages_unseen = [
          ...previousData.results.messages_unseen,
          ...response.data.results.messages_unseen,
        ];

        return response.data;
      });
      setRequestStatus(RequestState.Success);
    },
    [fetchingErrorHandler, isMounted, token],
  );

  const loadMoreNotificationData = useCallback(() => {
    requestData.offset = requestData.offset + requestData.limit;
    requestStatus !== RequestState.Pending && fetchNotificationData(requestData);
  }, [fetchNotificationData, requestStatus]);

  useEffect(() => {
    fetchNotificationData(requestData);

    // only once when mounting a component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestData.offset = 0;
    needToCheckData && fetchNotificationData(requestData);
    setNeedToCheckData(false);
  }, [fetchNotificationData, needToCheckData, setNeedToCheckData]);

  return [requestStatus, fetchedNotificationData, loadMoreNotificationData];
}
