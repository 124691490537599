import { AbsoluteRoutes, LocalStorageKeys } from '../enums';
import { ECONNABORTED, ERR_INTERNET_DISCONNECTED } from '../const/interceptor_err';
import { HeadersEnum, TokensEnum, URLS } from '../const';
import axios, { type AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { LocalStorageService } from './localStorageService';
import api from './api';

let isRefreshing = false;
let subscribers: ((token: string) => void)[] = [];

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const access = LocalStorageService.getItem(LocalStorageKeys.Access);
  const language = LocalStorageService.getItem(LocalStorageKeys.Language);
  config.headers[TokensEnum.AUTHORIZATION] = `${TokensEnum.TYPE} ${access}`;
  config.headers[TokensEnum.LANGUAGE] = `${language}`;
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const currentVersion = LocalStorageService.getItem(LocalStorageKeys.FrontendVersion);
  const newVersion = response.headers[HeadersEnum.FRONTEND_VERSION];
  if (!!newVersion && !!currentVersion && Number(newVersion) > Number(currentVersion)) {
    LocalStorageService.setItem(LocalStorageKeys.FrontendVersion, newVersion);
    window.location.reload();
  }
  !currentVersion &&
    LocalStorageService.setItem(LocalStorageKeys.FrontendVersion, response.headers[HeadersEnum.FRONTEND_VERSION]);

  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError | AxiosResponse> => {
  if (error.response) {
    const { status, data, config } = error.response;

    if (status === 401) {
      const refreshToken = LocalStorageService.getItem(LocalStorageKeys.Refresh);

      if (data.code === TokensEnum.REFRESH_INVALID) {
        // Refresh token is not valid, perform logout or other actions
        LocalStorageService.clear();
        window.location.replace(AbsoluteRoutes.Login);
      } else if (data.code === TokensEnum.ACCESS_INVALID) {
        // Token is not valid, try refreshing the token
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const rs = await api.post(`${TokensEnum.API_URL}/${URLS.REFRESH}`, {
              refresh: refreshToken,
            });

            const { access, refresh } = rs.data;

            LocalStorageService.setItem(LocalStorageKeys.Access, access);
            LocalStorageService.setItem(LocalStorageKeys.Refresh, refresh);

            // Notify all the waiting subscribers
            subscribers.forEach(callback => callback(access));
            subscribers = [];

            // Retry failed request
            config.headers.Authorization = `${TokensEnum.TYPE} ${access}`;
            return axios(config);
          } catch (refreshError) {
            // Handle refresh token error, e.g., logout user
            LocalStorageService.clear();
            window.location.replace(AbsoluteRoutes.Login);
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          // If token is already refreshing, add this request to the subscribers list
          return new Promise(resolve => {
            subscribers.push(token => {
              config.headers[TokensEnum.AUTHORIZATION] = `${TokensEnum.TYPE} ${token}`;
              resolve(api.request(config));
            });
          });
        }
      }
    }
  }
  if (
    error.code === ECONNABORTED ||
    !error.response ||
    error.code === ERR_INTERNET_DISCONNECTED ||
    error.message.includes('Network Error')
  ) {
    const customErr = {
      ...error,
      response: { status: 504 },
    };
    return Promise.reject(customErr);
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
