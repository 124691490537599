import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';

const api = setupInterceptorsTo(
  axios.create({
    baseURL: 'https://api.pets.kruts.net/api/v1',
    timeout: 15000,
  }),
);

export default api;
