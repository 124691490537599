import './AdvHorizontal.scss';

import { ProfileRoutePath } from '../../../profile/utils/const/route-path';
import { type ReactElement } from 'react';
import { SliderImage } from '../SliderImage/SliderImage';
import adv from '../../../../../assets/images/adv-min.svg';
import { initialSupportData } from '../../../../../utils/const/advForm';
import { useHistory } from 'react-router';

export function AdvHorizontal({ height }: { height: number }): ReactElement {
  const history = useHistory();
  const onClick = () => history.push(`/${ProfileRoutePath.SUPPORT}`, { initialSupportData });
  return (
    <div className='advHorizontal' onClick={onClick}>
      <SliderImage imageSize={height * 1.3} url={adv} customClass='' customWidth={1.69} margin='1rem 0 0 0' />
    </div>
  );
}
