import {
  StyledListItem,
  StyledListItemDate,
  StyledListItemHeader,
  StyledListItemIcon,
  StyledListItemText,
} from './StyledComponents';

import type { TNotificationMessageProps } from '../types';
import { memo } from 'react';
import { useNotificationList } from '../hooks/useNotificationList';

export const NotificationMessageMemo: React.FC<TNotificationMessageProps> = memo(function NotificationMessage({
  type,
  color,
  icon,
  header,
  message,
  date,
  id,
  disabled = false,
  setNewMessageList,
  setReadMessageList,
  setReadMessagesIdList,
  currentNewMessageList,
  fullDate,
  customRef,
}: TNotificationMessageProps) {
  const { checkMessageAsRead } = useNotificationList({
    messageId: id,
    setNewMessageList,
    setReadMessageList,
    currentNewMessageList,
    setReadMessagesIdList,
    fullDate,
  });

  return (
    <StyledListItem
      disabled={disabled}
      color={color}
      onClick={checkMessageAsRead}
      ref={customRef}>
      <StyledListItemIcon>
        <img src={icon} alt='icon' />
      </StyledListItemIcon>
      <StyledListItemHeader className='message_header'>{header}</StyledListItemHeader>
      {!date.date ? (
        <StyledListItemDate gridArea='date' className='time'>
          {date.time}
        </StyledListItemDate>
      ) : (
        <StyledListItemDate gridArea='date' className='date'>
          {date.date}
        </StyledListItemDate>
      )}
      <StyledListItemText className='message_text'>{message}</StyledListItemText>
      {!!date.date && (
        <StyledListItemDate gridArea='time' className='time'>
          {date.time}
        </StyledListItemDate>
      )}
    </StyledListItem>
  );
});
