import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';

import { NotificationMessageColor } from '../../../../../../utils/enums';
import { calculateRem } from '../../../../../../utils/helpers';
import { theme } from '../../../../../../MUITheme';

export const StyledList = styled(List)({
  position: 'absolute',
  top: calculateRem(50),
  backgroundColor: 'white',
  width: 'calc(100% - 4rem)',
  padding: 0,
  margin: '0 2rem',
  boxShadow: `0 ${calculateRem(6)} ${calculateRem(7)} 0 ${theme.palette.shadow.main}`,
  zIndex: '3',
});
export const StyledListBox = styled(Box)({
  maxHeight: 'calc(var(--app-height) - 11.5rem)',
  overflow: 'hidden auto',
  border: `solid 1px ${theme.palette.primary.light}`,
});
export const StyledBox = styled(Box)({
  backgroundColor: theme.palette.text.primary,
  height: calculateRem(5),
  borderTop: `1px solid ${theme.palette.primary.light}`,
});

export const StyledAcceptBtn = styled(ListItemButton)({
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.text.primary,
  position: 'sticky',
  bottom: `-${calculateRem(1)}`,
  width: '100%',
  transition: 'none',
  padding: '1rem',
  '& .MuiTypography-root': { fontWeight: '700', fontSize: '0.8rem', textAlign: 'center', textTransform: 'uppercase' },
  '&:hover': {
    backgroundColor: theme.palette.warning.light,
    transition: 'none',
  },
  '&:active': {
    backgroundColor: theme.palette.warning.light,
    transition: 'none',
  },
  '&:focus': {
    backgroundColor: theme.palette.warning.light,
    transition: 'none',
  },
});

const notificationColorPanel: {
  [key in NotificationMessageColor]: string;
} = {
  [NotificationMessageColor.Red]: `${theme.palette.success.main}33`,
  [NotificationMessageColor.Yellow]: `${theme.palette.warning.dark}33`,
  [NotificationMessageColor.Green]: `${theme.palette.error.light}33`,
  [NotificationMessageColor.Gray]: theme.palette.secondary.light,
};

export const StyledListItem = styled(ListItem)(({ color }: { color: NotificationMessageColor | string }) => ({
  display: 'grid',
  gridTemplateColumns: '2rem 6fr 2rem',
  gridTemplateAreas: `'icon m-header date' 
                      'icon m-text time'`,
  backgroundColor:
    typeof color === 'string' && Object.values(NotificationMessageColor).includes(color as NotificationMessageColor)
      ? notificationColorPanel[color as NotificationMessageColor]
      : color,
  fontFamily: 'Nunito-Regular, sans-serif',
  padding: '0.5rem 0.75rem 0.5rem 0.25rem',
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.primary.light}`,
  },
  '&.Mui-disabled': {
    opacity: 1,
    pointerEvents: 'all',
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)({
  gridArea: 'icon',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'start',
  maxWidth: '2.5rem',
  minWidth: 0,
  marginTop: '0.8rem',
});

export const StyledListItemHeader = styled(ListItemText)({
  gridArea: 'm-header',
  fontSize: '1rem',
  color: theme.palette.info.dark,
  margin: '0 0.5rem',

  '& .MuiTypography-root': {
    lineHeight: calculateRem(22),
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});
export const StyledListItemText = styled(Box)({
  gridArea: 'm-text',

  fontWeight: 400,
  fontSize: '1rem',
  color: theme.palette.info.dark,
  margin: '0 0.5rem',
  '& .MuiTypography-root': { lineHeight: calculateRem(22) },
});

export const StyledListItemDate = styled(Box)(({ gridArea }: { gridArea: string }) => ({
  gridArea: gridArea,
  fontWeight: 400,
  fontSize: '0.8rem',
  color: theme.palette.text.disabled,
  paddingTop: calculateRem(3),
  alignSelf: 'start',
  justifySelf: 'end',
}));
